<script setup>
 const props = defineProps({
  button: {
   type: [Object, Boolean],
   required: false,
   default: false,
  },
  label: {
   type: String,
   required: false,
   default: '',
  },
  link: {
   type: [String, Boolean],
   required: false,
   default: false,
  },
  icon: {
   type: [String, Boolean],
   required: false,
   default: false,
  },
  title: {
   type: String,
   required: false,
   default: '',
  },
  disabled: {
   type: Boolean,
   required: false,
   default: false,
  },
  forceInternalLink: {
   type: Boolean,
   required: false,
   default: false,
  },
  forceExternalLink: {
   type: Boolean,
   required: false,
   default: false,
  },
 });

 const { button, label, link, icon, title, disabled } = toRefs(props);

 const text = computed(() => {
  if (label.value) return label.value;
  if (button.value) return button.value.label;
  return '';
 });

 const buttonTitle = computed(() => {
  if (button.value) return button.value.title;
  if (title.value) return title.value;
  return false;
 });

 const href = computed(() => {
  if (link.value) return link.value;
  if (button.value) return button.value.url;
  return false;
 });

 const target = computed(() => {
  if (button.value) return button.value.target;
  return false;
 });
</script>

<template>
 <MessLink
  v-if="href && !disabled"
  class="mess-button base"
  :href="href"
  :target="target ? '_blank' : false"
  :title="buttonTitle || null"
  :forceInternalLink="forceInternalLink"
  :forceExternalLink="forceExternalLink"
 >
  <span class="wrapper">
   <span class="icon-wrapper" v-if="icon">
    <ProjectSvg :type="icon" />
   </span>
   <MessHtml :html="text" tag="span" />
  </span>
  <slot />
 </MessLink>
 <button v-else class="mess-button base" :title="title || null" :disabled="disabled">
  <span class="wrapper">
   <span class="icon-wrapper" v-if="icon">
    <ProjectSvg :type="icon" />
   </span>
   <MessHtml :html="text" tag="span" />
  </span>
  <slot />
 </button>
</template>
